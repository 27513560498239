<template>
  <base-layout-two :page-title="user.fullname" :page-default-back-link="`/users/${$route.params.id}`">
    <section class="padding-8">
      <base-card title="Record New Note">
        <v-form @submit="onSubmit">
          <!-- New Team Note -->
          <base-input label-text="Date *">
            <v-field name="datetime" v-slot="{ field }" v-model="activity.datetime" :rules="required">
              <ion-input name="date" v-bind="field" type="datetime-local"></ion-input>
            </v-field>
          </base-input>
          <v-error-message name="datetime" class="error-message">
          </v-error-message>

          <base-input label-text="Note *">
            <v-field name="activity" v-slot="{ field }" v-model="activity.notes" :rules="required">
              <ion-textarea name="activity" :auto-grow="true" rows="3" v-bind="field" placeholder="Details" autocapitalize
                autocorrect></ion-textarea>
            </v-field>
          </base-input>
          <v-error-message name="activity" class="error-message">
          </v-error-message>

          <ion-button type="submit" expand="block" class="margin-y-16">
            Save Note
          </ion-button>
        </v-form>
      </base-card>
    </section>
  </base-layout-two>
</template>

<script>
import { IonButton, IonTextarea, IonInput } from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";
import { format } from "date-fns";

export default {
  components: {
    IonButton,
    IonTextarea,
    IonInput,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      user: {},
      activity: {
        created_by_id: this.$store.getters.authUser.id,
        user_id: this.$route.params.id,
        type: "note",
        notes: "",
        datetime: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
      },
      required: yup.string().required(),
    };
  },

  ionViewWillEnter() {
    this.fetchUser();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async fetchUser() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/${this.$route.params.id}`)
        .then((response) => {
          this.user = response.data.user;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async onSubmit() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/activities`,
        data: { ...this.activity },
      };

      // Fix  Date
      if (config.data.datetime)
        config.data.datetime = new Date(config.data.datetime)
          .toISOString()
          .replace("T", " ")
          .replace("Z", "");

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.activity = response.data.activity;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Note Created", color: "secondary" });

          this.$router.replace(
            `/users/${this.$route.params.id}/activities/notes`
          );
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>